import { Component, Inject, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTabsModule } from '@angular/material/tabs';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { BankingService } from '../../features/banking/services/banking.service';
import { DialogRef } from '@angular/cdk/dialog';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartAccountsService } from '../../features/chart-of-accounts/services/chart-accounts.service';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-banking-details',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatSelectModule,
    AgGridAngular,
    MatTabsModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
  ],
  templateUrl: './banking-details.component.html',
  styleUrl: './banking-details.component.scss'
})
export class BankingDetailsComponent {

  selectedTabIndex = 0; 
  selectedTransaction = 'default';
  selectedOption = 'today';
  isDropdownOpen = false;
  showUncategorize = false;
  showAllTransaction = false;
  showAddTransaction = false;
  readonly dialog = inject(MatDialog);
  bankId: number;
  transactionData: any;
  transactions: any;
  withdrawal: any;
  deposit: any;
  transactionAmount: any;
  parentAccountId: any;
  isSubAccount: any;
  chartOfAccounts: any;
  transactionForm: FormGroup;
  accountType: any;
  search: any;
  selectedDate: any;
  file: any;
  statementAmount: any;
  statements: any;
  categoryId: number;
  categories: any[] = [];
  moneyInCategoriesExist: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private bankingService: BankingService,
    private chartAccountService: ChartAccountsService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private router: Router,
  ){}

  ngOnInit(){
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.bankId = Number(id);
      this.transactionOfChartOfAccount(this.bankId); 
      this.fetchChartAccounts();
      this.fetchTrasactionCategories();
      this.checkMoneyInCategories();
      this.initForm();
    }
  }


private checkMoneyInCategories(): void {
  this.moneyInCategoriesExist = this.categories?.some(category => category?.categoryStatus === 'In');
  console.log('moneyInCategoriesExist', this.moneyInCategoriesExist);
  
}

  initForm(){
    this.transactionForm = this.fb.group({
      offsetAccountId: this.bankId,
      fromAccount: [this.bankId],
      chartOfAccount: [''],
      vendor: [''],
      tds: [''],
      payment: [''],
      paid: [''],
      deposit: [''],
      customer: [''],
      date: [''],
      amount: [''],
      invoice: [''],
      description: [''],
      file: [''],
      flow: 'Debit',
      status: 'Manual'
    })
  }

  selectTab(index: number) {
    this.selectedTabIndex = index;
  }

  openDialog() {
    const dialogRef = this.dialog.open(UploadBankingDetailsDialog, {
      disableClose: true,
      data: this.bankId
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.transactionOfChartOfAccount(this.bankId);
    });
  }

  transactionOfChartOfAccount(id: any) {
    this.bankingService.transactionOfChartOfAccount(id).subscribe({
      next: (data) => {
        this.transactionData = data;
        this.statements = data?.statements;
          this.statementAmount = this.statements.map((statement: any) => ({
            ...statement,
            deposit: statement.flow === 'Credit' ? statement.amount : null,
            withdrawal: statement.flow === 'Debit' ? statement.amount : null,
          }));

        this.transactions = data?.transactions;
          this.transactionAmount = this.transactions.map((transaction: any) => ({
            ...transaction,
            deposit: transaction.flow === 'Credit' ? transaction.amount : null,
            withdrawal: transaction.flow === 'Debit' ? transaction.amount : null,
          }));
          console.log(this.transactionAmount);
      },
      error: (error) => {
        console.error(error);
      }
    })
  }

  fetchChartAccounts() {
    this.chartAccountService.chartOfAccounts(this.accountType, this.search, this.parentAccountId, this.isSubAccount).subscribe({
      next: (data) => {
        this.chartOfAccounts = data;
      },
      error: (error) => {
        console.error('Error fetching chart accounts:', error);
      }
    });
  }

  
  fetchTrasactionCategories(){
    this.bankingService.transactionCategories().subscribe({
      next: (data) => {
        if (data) {
          this.categories = data;
        }
      },
      error: (error) => { 
        this.toastr.error(error, 'Error')
      }
    })
  }

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  toggleCategorize(event?: MouseEvent, ) {
    if (event) {
      event.stopPropagation();
    }
    this.showUncategorize = !this.showUncategorize;
  }

  toggleAllTransaction(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.showAllTransaction = !this.showAllTransaction;
  }

  toggleAddTransaction(event: MatSelectChange) {
    const selectedTransaction = event.value;
    this.selectedTransaction = selectedTransaction.name;
    this.categoryId = selectedTransaction.id;
    console.log('Selected transaction:', this.selectedTransaction);
    if(this.selectedTransaction === 'expense'){
      this.router.navigate(['/expense/expense-new'], {
        queryParams: { expense: this.bankId, category: selectedTransaction.id },
      })
    } else if (this.selectedTransaction === 'vendor payment'){
      this.router.navigate(['/payments-made/bill-payment-new'], {
        queryParams: { expense: this.bankId, category: selectedTransaction.id },
      })
    }
    this.showAddTransaction = !this.showAddTransaction;
  }

  
  onFileSelected(event: any): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.file = file;
    }
  }

  closeDropdown(){
    this.showAddTransaction = !this.showAddTransaction;
    this.selectedTransaction = 'default';
  }
  
  onDateChange(event: any) {
    this.selectedDate = this.datePipe.transform(event.value, "yyyy-MM-dd");
  }
  
  onCancel(){
    this.transactionForm.reset();
  }

  removeFile(): void {
    this.file = null;
    const input = document.querySelector('input[type="file"]') as HTMLInputElement;
    if (input) {
      input.value = '';
    }
  }

  // onSave(){
  //   if (this.transactionForm.invalid) {
  //     this.transactionForm.markAllAsTouched();
  //     return;
  //   } 
  //   this.createTransaction();
  //   if(){}
  // }

  // createTransaction(){
  //   const transactionData = {
  //     date: this.datePipe.transform(this.transactionForm.value.date, 'yyyy-MM-dd'),
  //     transactionStatus: 'Manual',
  //     referenceNumber: this.transactionForm.value.invoice,
  //     amount: this.transactionForm.value.amount,
  //     description: this.transactionForm.value.description,
  //     flow: 'Debit',
  //     offsetAccountId: this.transactionForm.value.fromAccount,
  //     transactionCategoryId: Number(this.categoryId),
  //   }

  //   console.log(transactionData);
    
  //   this.bankingService.createTransaction(transactionData).subscribe({
  //     next: (data) => {
  //       if (data) {
  //         this.transactionOfChartOfAccount(this.bankId);
  //         this.toastr.success('Transaction added successfully', 'Success');
  //       }
  //     },
  //     error: (error) => { 
  //       this.toastr.error(error, 'Error')
  //     }
  //   })
  // }

  onSave() {
    if (this.transactionForm.invalid) {
      this.transactionForm.markAllAsTouched();
      return;
    }
  
    if (this.transactionForm.value.fromAccount) {
      // First call with `fromAccount` and flow as 'Debit'
      this.createTransaction(this.transactionForm.value.fromAccount, 'Debit', () => {
        // Second call with `chartOfAccount` (bankId) and flow as 'Credit'
        this.createTransaction(this.bankId, 'Credit', () => {
          this.transactionOfChartOfAccount(this.bankId);
          this.toastr.success('Transaction added successfully', 'Success');
        });
      });
    } else {
      // Single call if no fromAccount, with flow as 'Credit'
      this.createTransaction(this.transactionForm.value.chartOfAccount, 'Credit', () => {
        this.transactionOfChartOfAccount(this.bankId);
        this.toastr.success('Transaction added successfully', 'Success');
      });
    }
  }
  
  createTransaction(offsetAccountId: number, flow: 'Debit' | 'Credit', callback: () => void) {
    const transactionData = {
      date: this.datePipe.transform(this.transactionForm.value.date, 'yyyy-MM-dd'),
      transactionStatus: 'Manual',
      referenceNumber: this.transactionForm.value.invoice,
      amount: Number(this.transactionForm.value.amount),
      description: this.transactionForm.value.description,
      flow: flow, // Dynamic flow based on account type
      offsetAccountId: offsetAccountId, // Dynamic offsetAccountId
      transactionCategoryId: Number(this.categoryId),
    };
  
    console.log(transactionData);
  
    this.bankingService.createTransaction(transactionData).subscribe({
      next: (data) => {
        if (data) {
          callback();
        }
      },
      error: (error) => {
        this.toastr.error(error, 'Error');
      },
    });
  }
  

  // onSearch(event: Event) {
  //   const input = event.target as HTMLInputElement;
  //   this.search = input.value;
  //   // this.transactionOfChartOfAccount();
  // }

}


@Component({
  selector: 'upload-banking-details-dialog',
  templateUrl: 'upload-banking-details-dialog.html',
  styleUrls: ['./banking-details.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
})
export class UploadBankingDetailsDialog {
  file: any = null;
  accounts: any;
  search: any;

  constructor(
    private bankingService: BankingService,
    private toastr: ToastrService,
    private dialogRef: DialogRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ){}

  ngOnInit(){
    document.addEventListener('dragover', this.preventDefaultBehavior);
    document.addEventListener('drop', this.preventDefaultBehavior);
  }

  fetchAccountsOfBankAndCash() {
    this.bankingService.fetchAccountsOfBankAndCash(this.search).subscribe({
      next: (accounts: any) => {
        this.accounts = accounts;
      },
      error: (error) => { 
        console.error(error, 'Error');
      }
    })
  }

  preventDefaultBehavior(event: DragEvent) {
    event.preventDefault();
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();

    if (event.dataTransfer?.files.length) {
      this.onFileSelected({ target: { files: event.dataTransfer.files } });
    }
  }

  onFileSelected(event: any): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      console.log('Selected file:', file);
      this.file = file;
    }
  }

  removeFile(): void {
    this.file = null;
    const input = document.querySelector('input[type="file"]') as HTMLInputElement;
    if (input) {
      input.value = '';
    }
  }

  onUpload(){
    const accountId = this.data;
    this.bankingService.bulkCreateTransactions( this.file, accountId ).subscribe({
      next: (response) => {
        if (response && !response.errors) {
          this.toastr.success('Transactions uploaded successfully!', 'Success');
          console.log(accountId);
          this.dialogRef.close(accountId);
        }
        else if (response?.errors) {
          this.toastr.error(response?.errors[0]?.message, 'Error');
        }
      },
      error: (error) => {
        this.toastr.error(error, 'Bulk transaction creation failed');
        this.dialogRef.close();
      },
    });
  }
}