
  <section class="cmn-innerpage-wrapper employees">
    <div class="head">
      <div class="search-wrapper">
        <input
          type="text"
          class="cmn-header-search"
          placeholder="Search"
          (input)="onSearch($event)"
        />
      </div>
      <button
        class="cmn-filter-btn"
        (click)="toggleDropdown($event)"
        [ngClass]="{ 'class-clicked': isDropdownOpen }"
      >
        <img src="assets/images/icons/icon-filter.svg" alt="Filter Icon" />
        <span *ngIf="selectedCount > 0">{{ selectedCount }}</span>
      </button>
      <div
        [ngClass]="{ show: isDropdownOpen }"
        class="common-dropdown filter-position"
        #dropdown >
      
        <button
          class="close-dropdown"
          (click)="toggleDropdown($event)"
        ></button>
        <form [formGroup]="filterForm" (ngSubmit)="onSubmit()">
          <h6 class="cmn-popup-heading">
            Filter <span (click)="clearFilter()">Clear All</span>
          </h6>
          <div class="filter-inner-content">
            <h2>Branch</h2>
            <div
              formArrayName="branches"
              *ngIf="branchData.length > 0"
              class="check-wrapper"
            >
              <div
                class="check-box"
                *ngFor="let branch of branchData; let i = index"
              >
                <label class="checkbox-wrapper">
                  <input
                    type="checkbox"
                    [formControlName]="i"
                    (change)="onCheckboxChange()"
                  />
                  {{ branch.branchName }}
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
            <h2>Employee Type</h2>
            <div
              formArrayName="employeeTypes"
              *ngIf="employeeTypeData.length > 0"
              class="check-wrapper"
            >
              <div
                class="check-box"
                *ngFor="let employeeType of employeeTypeData; let i = index"
              >
                <label class="checkbox-wrapper">
                  <input
                    type="checkbox"
                    [formControlName]="i"
                    (change)="onCheckboxChange()"
                  />
                  {{ employeeType.employeeType }}
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="btn-wrapper">
            <button class="cmn-apply-btn" type="submit">Apply</button>
          </div>
        </form>
      </div>
      <button class="cmn-upload-btn" (click)="openDialog()">
        <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
        <span>Upload</span>
      </button>
      <button class="cmn-add-btn" (click)="navigateToAddEmployee()">
        <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> Add New
        Employee
      </button>
      <button class="cmn-export-btn" (click)="toggleExport($event)">
        <img src="assets/images/icons/icon-export.svg" alt="Filter Icon" />
        <!-- <span>Export</span> -->
      </button>
      <div
        [ngClass]="{ show: isExportOpen }"
        class="common-dropdown export-position"
        #export
      >
        <button class="close-dropdown" (click)="toggleExport($event)"></button>
        <h2>Export Table</h2>

        <div class="check-wrapper">
          <div class="check-box">
            <label class="checkbox-wrapper">
              <input
                type="checkbox"
                (change)="onFieldCheckboxChange($event, 'firstName')"
              />Employee Name
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="check-box">
            <label class="checkbox-wrapper">
              <input
                type="checkbox"
                (change)="onFieldCheckboxChange($event, 'employeeCode')"
              />Employee Code
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="check-box">
            <label class="checkbox-wrapper">
              <input
                type="checkbox"
                (change)="onFieldCheckboxChange($event, 'employeeType')"
              />Type
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="check-box">
            <label class="checkbox-wrapper">
              <input
                type="checkbox"
                (change)="onFieldCheckboxChange($event, 'employeeStatus')"
              />Status
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="check-box">
            <label class="checkbox-wrapper">
              <input
                type="checkbox"
                (change)="onFieldCheckboxChange($event, 'branchName')"
              />Branch
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="check-box">
            <label class="checkbox-wrapper">
              <input
                type="checkbox"
                (change)="onFieldCheckboxChange($event, 'email')"
              />Email
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="check-box">
            <label class="checkbox-wrapper">
              <input
                type="checkbox"
                (change)="onFieldCheckboxChange($event, 'nationality')"
              />Nationality
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="check-box">
            <label class="checkbox-wrapper">
              <input
                type="checkbox"
                (change)="onFieldCheckboxChange($event, 'designationName')"
              />Designation
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="check-box">
            <label class="checkbox-wrapper">
              <input
                type="checkbox"
                (change)="onFieldCheckboxChange($event, 'departmentName')"
              />Department
              <span class="checkmark"></span>
            </label>
          </div>
        </div>

        <h2>File Type</h2>

        <!-- Format Selection -->
        <div class="format-selection">
          <label class="radiobtn-wrapper">
            <input
              type="radio"
              name="exportFormat"
              value="pdf"
              [(ngModel)]="exportFormat"
            />
            <span class="radiomark"></span>
            PDF
          </label>
          <label class="radiobtn-wrapper">
            <input
              type="radio"
              name="exportFormat"
              value="xls"
              [(ngModel)]="exportFormat"
            />
            <span class="radiomark"></span>
            XLS
          </label>
          <label class="radiobtn-wrapper">
            <input
              type="radio"
              name="exportFormat"
              value="csv"
              [(ngModel)]="exportFormat"
            />
            <span class="radiomark"></span>
            CSV
          </label>
        </div>

        <div class="btn-wrapper">
          <button
            class="cmn-apply-btn"
            type="submit"
            (click)="exportEmployees()"
          >
            Export
          </button>
        </div>
      </div>
    </div>
    <div class="inner-content">
      <ag-grid-angular
        style="width: 100%"
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowSelection]="rowSelection"
        [suppressRowClickSelection]="true"
        [pagination]="true"
        [paginationPageSize]="paginationPageSize"
        [paginationPageSizeSelector]="paginationPageSizeSelector"
        [class]="themeClass"
        (rowClicked)="onRowClicked($event)"
      />
    </div>
  </section>

