import { Component, inject } from '@angular/core';

import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { PaymentsItemService } from '../../features/payments/services/payments.service';
import {
  PAYMENT_MODES,
  PartnerTypesEnum,
  PaymentTypesEnum,
} from '../../helpers/helper-file';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { UploadFileService } from '../../shared/services/file-upload.service';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { pymentGenerateNo } from '../../helpers/helper';
import { CreateClientDialog } from '../invoice-new/dialog-components/create-customer-dialog/create-client-dialog.component';

@Component({
  selector: 'app-payment-new',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
  ],
  templateUrl: './payment-new.component.html',
  styleUrl: './payment-new.component.scss',
})
export class PaymentNewComponent {
  paymentNewItemForm: FormGroup;
  documentFile: any = null;
  documentFileName: any = null;
  documentFileId: any = null;
  documentFileUrl: any = null;

  partnersList: any;
  paymentModeList: any = PAYMENT_MODES;
  getAccountList: any;
  partnerInvoiceData: any;
  private partnerTypes = PartnerTypesEnum;
  private paymentType = PaymentTypesEnum;
  readonly dialog = inject(MatDialog);
  fullAmount: number = 0.0;
  splitAmounts: number[] = []; // Array to store split values
  totalSplitAmount = 0; // Variable to store the total
  isLoading = false;
  customerSearchControl = new FormControl();
  maxDate: Date = new Date();
  globalInvoiceId: number;

  constructor(
    private fb: FormBuilder,
    private itemService: PaymentsItemService,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private documentUploadService: UploadFileService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.initForm();
    this.fetchPartners();
    this.fetchGetAccount();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const id = params['id'];
      const customerId = params['customerId'];
      const invoiceId = params['invoiceId'];
      this.globalInvoiceId = parseInt(invoiceId);

      if (id) {
        this.paymentNewItemForm.get('id')?.setValue(id);
        this.loadInvoiceData();
      } else {
        const generatedId = pymentGenerateNo();
        this.paymentNewItemForm.get('payment')?.setValue(generatedId);
        this.paymentNewItemForm.get('id')?.setValue('');
      }

      this.customerSearchControl.valueChanges
        .pipe(debounceTime(300), distinctUntilChanged())
        .subscribe((searchText) => {
          this.fetchPartners(searchText);
        });

      if (customerId && invoiceId) {
        this.getDueInvoicesByPartner(parseInt(customerId));
        this.paymentNewItemForm
          .get('customerName')
          ?.setValue(parseInt(customerId));
      }
    });

    // Recalculate splits and total whenever amountReceived changes
    this.paymentNewItemForm
      .get('amountReceived')
      ?.valueChanges.subscribe(() => {
        this.calculateSplits();
      });

    // Initial calculation
    this.calculateSplits();

    //calculateExcessAmount
    this.paymentNewItemForm
      .get('amountReceived')
      ?.valueChanges.subscribe(() => {
        this.calculateExcessAmount();
        this.usedAmount();
      });
  }

  private loadInvoiceData(): void {
    const id = this.paymentNewItemForm.get('id')?.value;
    if (id) {
      this.itemService.fetchPayment(parseInt(id)).subscribe({
        next: (data) => {
          this.getDueInvoicesByPartner(data?.partnerId);

          this.documentFileName = data?.paymentAttachments?.[0]?.fileName;
          this.documentFileId = data?.paymentAttachments?.[0]?.id;
          this.documentFileUrl = data?.paymentAttachments?.[0]?.fileUrl;

          this.paymentNewItemForm.patchValue({
            id: data?.id,
            customerName: data?.partnerId,
            bankCharges: data?.bankCharge,
            amountReceived: data.amount,
            paymentDate: new Date(+data?.paymentDate),
            payment: data.paymentNo,
            paymentMode: data.paymentMode,
            depositTo: data.depositId,
            reference: data.referenceNo,
            note: data.note,
          });
        },
        error: (error) => console.error(error),
      });
    }
  }

  openCreateClientDialog() {
    this.dialog.open(CreateClientDialog);
  }

  initForm(): void {
    this.paymentNewItemForm = this.fb.group({
      id: [''],
      customerName: ['', Validators.required],
      bankCharges: [''],
      amountReceived: ['', Validators.required],
      paymentDate: ['', Validators.required],
      payment: ['', Validators.required],
      paymentMode: ['', Validators.required],
      depositTo: ['', Validators.required],
      reference: [''],
      // tax: ['', Validators.required],
      note: [''],
      isFullAmount: [false],
    });
  }

  private fetchPartners(search: string = '') {
    this.itemService
      .fetchPartners(search, this.partnerTypes.Customer)
      ?.subscribe({
        next: (partners: any) => {
          this.partnersList = partners;
        },
        error: (error: any) => console.error(error),
      });
  }
  private fetchGetAccount() {
    this.itemService.fetchDepositTo('', true, false)?.subscribe({
      next: (getAccount: any) => {
        this.getAccountList = getAccount;
      },
      error: (error: any) => console.error(error),
    });
  }
  private getDueInvoicesByPartner(id: number) {
    this.itemService.getDueInvoicesByPartner(id, {})?.subscribe({
      next: (invoiceData: any) => {
        if (this.globalInvoiceId) {
          this.partnerInvoiceData = invoiceData.filter(
            (item: any) => item.id === this.globalInvoiceId
          );
        } else {
          this.partnerInvoiceData = invoiceData;
        }
        this.fullAmount = this.partnerInvoiceData?.reduce(
          (total: number, item: { balance: number }) => total + item.balance,
          0
        );
      },
      error: (error: any) => console.error(error),
    });
  }

  onCustomerSelected(event: MatSelectChange) {
    const selectedCustomerId = event.value;
    this.paymentNewItemForm.get('amountReceived')?.setValue('');
    this.paymentNewItemForm.get('isFullAmount')?.setValue(false);
    this.getDueInvoicesByPartner(selectedCustomerId);
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.documentFileName = file.name;
      this.documentFile = file;
    }
  }

  clearDocument() {
    this.documentFile = null;
    this.documentFileName = null;
  }

  toggleFullAmount(event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      this.paymentNewItemForm.get('amountReceived')?.setValue(this.fullAmount);
    } else {
      this.paymentNewItemForm.get('amountReceived')?.setValue('');
    }
  }

  // calculateSplits(): void {
  //   const amountReceived =
  //     this.paymentNewItemForm.get('amountReceived')?.value || 0;
  //   const totalEntries = this.partnerInvoiceData?.length;
  //   const splitAmount = totalEntries > 0 ? amountReceived / totalEntries : 0;

  //   // Populate the splitAmounts array
  //   this.splitAmounts = Array(totalEntries).fill(splitAmount);

  //   // Calculate the total of split amounts
  //   this.totalSplitAmount = this.splitAmounts.reduce(
  //     (acc, curr) => acc + curr,
  //     0
  //   );
  // }

  calculateSplits(): void {
    const amountReceived =
      this.paymentNewItemForm.get('amountReceived')?.value || 0;
    const totalEntries = this.partnerInvoiceData?.length || 0;
    if (totalEntries > 0) {
      const totalBalance = this.partnerInvoiceData.reduce(
        (sum: any, invoice: any) => sum + (invoice.balance || 0),
        0
      );
      this.splitAmounts = this.partnerInvoiceData.map((invoice: any) => {
        const balance = invoice.balance || 0;
        return totalBalance > 0 ? (balance / totalBalance) * amountReceived : 0;
      });
    } else {
      this.splitAmounts = [];
    }
  }

  calculateExcessAmount(): number {
    const amountReceived =
      this.paymentNewItemForm.get('amountReceived')?.value || 0;
    return amountReceived - this.totalSplitAmount;
  }

  usedAmount(): number {
    const amountReceived =
      this.paymentNewItemForm.get('amountReceived')?.value || 0;
    return amountReceived;
  }

  onCreatePayment(): void {
    if (this.paymentNewItemForm.invalid) {
      this.paymentNewItemForm.markAllAsTouched();
      return;
    }

    this.documentUploadService
      .uploadDocument(this.documentFile, 'payment-received')
      .subscribe({
        next: (url) => {
          if (url) {
            this.documentFileUrl = url?.url;
          }
          const data = this.paymentNewItemForm.value;
          const invoiceIds = this.partnerInvoiceData?.map(
            (item: any) => item?.id
          );

          const itemInputData = {
            partnerId: data?.customerName,
            bankCharge: parseFloat(data?.bankCharges),
            paymentType: this.paymentType.INVOICE,
            amount: parseFloat(data?.amountReceived),
            paymentDate:
              this.datePipe.transform(data?.paymentDate, 'yyyy/MM/dd') || '--',
            paymentNo: data?.payment,
            referenceNo: data?.reference,
            depositId: data?.depositTo,
            paymentMode: data?.paymentMode,
            totalAmount: parseFloat(data?.amountReceived),
            // receiptUrl: url?.url,
            note: data?.note,
          };

          const createPaymentAttachment = {
            fileUrl: this.documentFileUrl,
            fileName: this.documentFileName,
          };

          const invoiceIdsValue = invoiceIds;
          const billIdsValue: any = null;
          this.isLoading = true;

          if (data?.id) {
            //updateing
            const itemInputUpdateData = {
              id: data?.id,
              ...itemInputData,
            };

            const createPaymentUpdateAttachment = {
              id: this.documentFileId ?? null,
              ...createPaymentAttachment,
            };

            this.itemService
              .updatePayment(
                itemInputUpdateData,
                createPaymentUpdateAttachment,
                invoiceIdsValue,
                billIdsValue
              )
              .subscribe({
                next: () => {
                  this.isLoading = false;
                  this.toastr.success('Payment Received updated successfully.');
                  this.router.navigate(['/payment-received']);
                },
                error: (error) => {
                  this.isLoading = false;
                  this.toastr.error(error.message || 'Failed to update item.');
                },
              });
          } else {
            this.itemService
              .createPayment(
                itemInputData,
                createPaymentAttachment,
                invoiceIdsValue,
                billIdsValue
              )
              .subscribe({
                next: () => {
                  this.isLoading = false;
                  this.toastr.success('Payment Received Created successfully.');
                  this.router.navigate(['/payment-received']);
                },
                error: (error) => {
                  this.isLoading = false;
                  this.toastr.error(error.message || 'Failed to update item.');
                },
              });
          }
        },
        error: (error) => {
          this.toastr.error('upload failed', error);
        },
      });
  }
}
